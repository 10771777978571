import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';

// PAGES
import Index from './pages/Index';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={ <Navigate to='/' replace /> } />
          <Route path="/" element={ <Index /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
