import React, { useState } from "react";
import { useQuill } from "react-quilljs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';

// QUILL'S STYLES
import 'quill/dist/quill.snow.css';

// TOOLBAR
import toolbar from '../configs/toolbar';

// BACKEND
import { BACK_ENDPOINT } from "../const";

export default function Editor(props) {

    const [data, setData] = useState({});
    const [img, setImg] = useState();
    const [text, setText] = useState("");

    // QUILL'S HOOK
    const { quill, quillRef } = useQuill({
        modules: {

            toolbar: toolbar

        }
    });

    const handleChange = e => {

        setData({

            ...data,
            [e.target.id]: e.target.value

        });

    }

    const navigate = useNavigate();

    const handleSubmit = async (e) => {

        e.preventDefault();

        const d = {
            title: data.title,
            category: data.category,
            content: JSON.stringify(quill.getContents())
        }

        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("category", data.category);
        formData.append("main_img", img);
        formData.append("content", JSON.stringify(quill.getContents()));

        await axios.post(BACK_ENDPOINT + 'news/', formData)
            .then(res => {

                console.log(res.data);
                setText(res.data)
                $('.text-result').addClass('visible');
                setTimeout(()=>{
                    $('.text-result').removeClass('visible');
                    setText("");
                }, 6000)
                e.target.reset();

                // NAVIGATE TO NEWS PAGE
                //navigate('/news');

            })
            .catch(err => {

                console.log(err)

            })

    }

    const handleChangeFile = (e) => {

        setImg(e.target.files[0])

    }

    return (

        <div className="col-12 col-lg-6 mx-auto mt-5">
            <form onSubmit={handleSubmit} className="col-12 col-lg-10 mx-auto news-form" encType='multipart/form-data'>
                <div className="mb-3">
                    <label className="form-label" htmlFor="title">
                        TÍTULO
                    </label>
                    <input type='text' placeholder="Escriba el título..." id='title' onChange={handleChange} className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="title">
                        CATEGORÍA
                    </label>
                    <select onChange={handleChange} id='category' className="form-control">
                        <option value={''}>
                            -- SELECCIONE --
                        </option>
                        {
                            props.categories.map((c, i) => {

                                return (
                                    <option value={c.id}>
                                        {c.name}
                                    </option>
                                )

                            })
                        }
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label" htmlFor="main_img">
                        PORTADA
                    </label>
                    <input type='file' id='main_img' onChange={handleChangeFile} accept="img/png img/jpeg" className="form-control" />
                    <span style={{ 'fontSize': '.75rem', 'marginLeft': '.75rem' }}>
                        La portada debe tener una relación de 16:9
                    </span>
                </div>
                <div>
                    <div className="editor">
                        <div ref={quillRef}>

                        </div>
                    </div>
                </div>
                <div className="mt-3 text-end">
                    <button type="reset" className="me-3 ">
                        Resetear
                    </button>
                    <button type="submit" className="me-3 ">
                        Publicar
                    </button>
                    <span className="text-result">{ text }</span>
                </div>
            </form>
        </div>

    )

}