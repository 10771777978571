import React, { useState, useEffect } from "react";
import axios from "axios";

// BACKEND
import { BACK_ENDPOINT } from "../const";

// COMPONENTS
import Editor from "../components/editor";

export default function Index() {

    const [ categories, setCategories ] = useState([]);

    useEffect(() => {

        getCategories();

    }, [])

    const getCategories = async() => {

        try{

            const res = await axios.get(BACK_ENDPOINT + 'news-categories/');
            setCategories(res.data)

        } catch (err) {

            console.log('Error: ', err);

        }

    }

    return(

        <div>
            <Editor categories={ categories } />
        </div>

    )

}